import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import quoteInProgressClient from 'api/quoteInProgressClient';
import { navigate, PageProps } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { getQueryParam } from 'helpers/getQueryParam';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useBaseApiRequestHandler } from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import { RESET_QUOTE } from 'state/quote/quote';
import {
  QuoteParameters,
  useQuoteParameters,
} from 'state/quoteParameters/quoteParameters';
import useDispatch from 'state/useDispatch';

const StartAQuote: React.FC<PageProps> = ({ location }) => {
  const [, updateQuoteParameters] = useQuoteParameters();
  const requestHandler = useBaseApiRequestHandler();
  const dispatch = useDispatch();

  useEffect(() => {
    const validCodes = [
      ['cdwAffiliateCode', getQueryParam(location, 'cdw_affiliate_code')],
      ['campaignCode', getQueryParam(location, 'campaignCode')],
      ['refToken', getQueryParam(location, 'refToken')],
      ['recaptchaOverrideKey', getQueryParam(location, 'recaptchaOverrideKey')],
    ].filter(([, codeValue]) => !!codeValue);
    const quoteParameters: QuoteParameters = Object.fromEntries(validCodes);
    requestHandler(
      async (): Promise<void> => {
        await quoteInProgressClient.deleteQuoteInProgress();
        dispatch({ type: RESET_QUOTE });
        updateQuoteParameters(quoteParameters);
        if (!isEmpty(quoteParameters)) {
          await quoteInProgressClient.saveQuoteInProgress({ quoteParameters });
        }
        navigate(quoteAndBuyRoutes.aboutYourCar());
      }
    );
  }, [dispatch, location, requestHandler, updateQuoteParameters]);

  const metaTitle = 'Starting your quote';
  usePageTracking(metaTitle);

  return (
    <Layout meta={{ meta_title: metaTitle }} pageType="quoteAndBuy">
      <LoadingOverlay loadingMessage="Starting your quote" />
    </Layout>
  );
};

export default StartAQuote;
